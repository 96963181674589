<template>
  <div class="pa-2 ma-2">
    <v-container style="height: 400px;" v-if="loading">
      <v-row class="fill-height" align-content="center" justify="center">
        <v-col class="text-subtitle-1 text-center" cols="12"> Loading </v-col>
        <v-col cols="6">
          <v-progress-linear color="deep-purple accent-4" indeterminate rounded height="6"></v-progress-linear>
        </v-col>
      </v-row>
    </v-container>
    <template v-else>
      <v-tabs v-if="modelObj._id && subModulesList.length" v-model="tab" background-color="black" dark slider-color="white" grow class="pt-0 mt-0">
        <v-tab v-for="(tab, index) in subModulesList" :key="index">
          <v-icon class="mr-2">{{ tab.icon }}</v-icon> {{ tab.name }} 
        </v-tab>
      </v-tabs>
      <div v-if="tab === 0 && fields && fields.length">
        <form-fields :fields="fields" :modelObj="modelObj.formdata" ref="form"/>
      </div>
      <div v-else>
        <sub-module v-if="subModulesList && subModulesList.length" :formId="subModulesList[tab].formId" :parentFormId="formId" :parentRecordId="modelObj._id"></sub-module>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: ['formId', 'modelObj'],
  data () {
    return {
      fields: [],
      subModulesList: [],
      tab: 0,
      lookupModuleItems: {},
      loading: false,
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 20 || 'Max 20 characters',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
    }
  },
  mounted () {
    this.getFormProperties()
  },
  components: {
    'sub-module': () => import('@/components/CustomModule/Index.vue'),
    'form-fields': () => import('@/views/FormBuilder/Builder/Components/FormFields.vue')
  },
  methods: {
    getFormProperties () {
      this.$_execute('get', `forms/${this.formId}`).then(({ data }) => {
        if (data.configuration?.relatedModules.length) {
          const subModulesList = [{
            name: 'Detail',
            icon: 'mdi-details'
          }]
          data.configuration.relatedModules.forEach((form) => {
            const formObj = form.formId ? form.formId : null
            if (formObj) {
              subModulesList.push({
                icon: 'mdi-details',
                name: formObj.name,
                formId: formObj._id
              })
            }
          })
          this.subModulesList = subModulesList
        }
        this.fields = data && data.fields ? data.fields : []
        this.$emit('formLoaded')
      })
    }
  }
}
</script>
